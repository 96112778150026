<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Detalle de comprobante de compra</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Providers-->
        <div class="w-full">
          <vs-input label="Proveedor" class="w-full" v-model="document.provider.businessName" disabled/>
        </div>
        <!-- Gloss and type -->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled label="Glosa"
                      v-model="document.gloss"
                      class="w-full" name="gloss" id="gloss"/>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled label="Tipo de gasto"
                      v-model="document.type"
                      class="w-full" name="type" id="type"/>
          </div>
        </div>
        <!-- Currency and Payment method-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled label="Moneda"
                      v-model="document.currency.value"
                      class="w-full" name="currency" id="currency"/>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled label="Método de pago"
                      v-model="document.paymentMethod.value"
                      class="w-full" name="paymentMethod" id="paymentMethod"/>
          </div>
        </div>
        <!--Dates-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="dateOfIssue">Fecha de emisión*</label>
            <datepicker disabled :language="es" label="Fecha de emisión" v-model="document.dateOfIssue" id="dateOfIssue"
                        name="dateOfIssue"></datepicker>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="endDate">Fecha de vencimiento*</label>
            <datepicker disabled :language="es" label="Fecha de vencimiento" v-model="document.endDate" id="endDate"
                        v-validate="'required'" name="endDate"></datepicker>
          </div>
        </div>
        <!--Doc-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled @blur="document.serie= trimString(document.serie)" label="Serie*"
                      v-model="document.serie"
                      class="w-full" name="serie"/>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input disabled @blur="document.sequence= trimString(document.sequence)" label="Nro. Doc*"
                      v-model="document.sequence"
                      class="w-full" name="sequence"/>
          </div>
        </div>

        <vs-divider></vs-divider>

        <!--Amounts-->
        <div class="vx-row">
          <div class="vx-col w-3/4"></div>
          <div class="vx-col w-1/4">
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Sub total: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency.code ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(document.base)
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">IGV: </span>
              <span>
                {{
                  Intl.NumberFormat('en-IN', {
                    style: 'currency',
                    currency: document.currency.code ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format((document.igv))
                }}
              </span>
            </div>
            <!--Divider-->
            <vs-divider class="mt-5"></vs-divider>
            <div class="flex flex-wrap items-center justify-between">
              <span class="font-bold">Total: </span>
              <span>
                {{
                  Intl.NumberFormat('en', {
                    style: 'currency',
                    currency: document.currency.code ? document.currency.code : 'PEN',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(document.total)
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cerrar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import Datepicker from 'vuejs-datepicker'

import { es } from 'vuejs-datepicker/dist/locale'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        provider: {},
        currency: {},
        paymentMethod: {}
      },
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      es: es
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          ...this.data
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 1000px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
