<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Ingresar comprobante de compra</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Type & Providers-->
        <div class="vx-row">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Proveedor*</label>
            <v-select :options="providers" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.provider" class="mb-4 md:mb-0" name="provider" id="provider"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('provider')">{{ errors.first('provider') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Tipo de comprobante*</label>
            <v-select placeholder="Selecciona" :options="typesOfInvoices" :clearable="false"
                      label="value"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.typeOfInvoice" class="mb-4 md:mb-0" name="type" id="type"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>
        </div>
        <!-- Gloss and type -->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @keydown.enter="extractDataToQR" @blur="document.gloss= trimString(document.gloss)" label="Glosa"
                      v-model="document.gloss"
                      class="w-full" name="gloss" id="gloss"
                      v-validate="'max:100|min:5'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('gloss')">{{ errors.first('gloss') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Tipo de gasto*</label>
            <v-select placeholder="Selecciona" :options="types" :clearable="false"
                      label="value"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.type" class="mb-4 md:mb-0" name="type" id="type"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>
        </div>
        <!-- Currency and Payment method-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Moneda*</label>
            <v-select :options="currencies" :clearable="false"
                      label="value"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.currency" class="mb-4 md:mb-0" name="currency" id="currency"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('currency')">{{ errors.first('currency') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="text-sm opacity-75">Forma de pago*</label>
            <v-select @input="calculateEndDate" placeholder="Selecciona" :options="paymentMethods" :clearable="false"
                      label="value"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.paymentMethod" class="mb-4 md:mb-0" name="paymentMethod" id="paymentMethod"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('paymentMethod')">{{ errors.first('paymentMethod') }}</span>
          </div>
        </div>
        <!--Dates-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="dateOfIssue">Fecha de emisión*</label>
            <datepicker @input="calculateEndDate" :language="es" label="Fecha de emisión"
                        v-model="document.dateOfIssue"
                        id="dateOfIssue"
                        v-validate="'required'" name="dateOfIssue"></datepicker>
            <span class="text-danger text-sm"
                  v-show="errors.has('dateOfIssue')">{{ errors.first('dateOfIssue') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="endDate">Fecha de vencimiento*</label>
            <datepicker :language="es" label="Fecha de vencimiento" v-model="document.endDate" id="endDate"
                        v-validate="'required'" name="endDate"></datepicker>
            <span class="text-danger text-sm"
                  v-show="errors.has('endDate')">{{ errors.first('endDate') }}</span>
          </div>
        </div>
        <!--Period-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="year">Año*</label>
            <v-select placeholder="Selecciona" :options="years" :clearable="false"
                      label="value"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.year" class="mb-4 md:mb-0" name="year" id="year"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('year')">{{ errors.first('year') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <label class="vs-select--label" for="month">Mes*</label>
            <v-select placeholder="Selecciona" :options="months" :clearable="false"
                      label="name"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="document.month" class="mb-4 md:mb-0" name="month" id="month"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('month')">{{ errors.first('month') }}</span>
          </div>
        </div>
        <!--Doc-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.serie= trimString(document.serie)" label="Serie*"
                      v-model="document.serie"
                      class="w-full" name="serie"
                      v-validate="'required|max:4'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('serie')">{{ errors.first('serie') }}</span>
          </div>
          <div class="vx-col w-1/2 md:w-1/2 xl:w-1/2">
            <vs-input @blur="document.sequence= trimString(document.sequence)" label="Nro. Doc*"
                      v-model="document.sequence"
                      class="w-full" name="sequence"
                      v-validate="'required|max:10'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('sequence')">{{ errors.first('sequence') }}</span>
          </div>
        </div>
        <!--Amounts-->
        <div class="vx-row mt-5">
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input
                @input="document.igv= parseFloat((document.base*0.18).toFixed(2)), document.total = parseFloat((document.base +  document.igv + document.unaffected).toFixed(2))"
                type="number"
                :label="`Base imponible* (${document.currency.symbol})`"
                v-model.number="document.base"
                class="w-full" name="base"
                v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('base')">{{ errors.first('base') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input @input="document.total = (document.base +  document.igv + document.unaffected)" type="number"
                      :label="`Inafecto* (${document.currency.symbol})`"
                      v-model.number="document.unaffected"
                      class="w-full" name="unaffected"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('unaffected')">{{ errors.first('unaffected') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled="true" type="number"
                      :label="`IGV* (${document.currency.symbol})`"
                      v-model.number="document.igv"
                      class="w-full" name="igv"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('igv')">{{ errors.first('igv') }}</span>
          </div>
          <div class="vx-col w-1/4 md:w-1/4 xl:w-1/4">
            <vs-input disabled="true" type="number"
                      :label="`Total* (${document.currency.symbol})`"
                      v-model.number="document.total"
                      class="w-full" name="total"
                      v-validate="'required'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('total')">{{ errors.first('total') }}</span>
          </div>
        </div>
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Guardar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import Datepicker from 'vuejs-datepicker'

import {db, FieldValue, auth} from '@/firebase/firebaseConfig'

import {es} from 'vuejs-datepicker/dist/locale'
import axios from 'axios'

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    providers: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  mixins: [trimString],
  data () {
    return {
      document: {
        currency: null,
        paymentMethod: {
          id: 30,
          value: 'Crédito a 30 días'
        },
        base: 0,
        unaffected: 0,
        igv: 0,
        total: 0
      },
      currencies: [
        {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        },
        {
          id: 2,
          value: 'Dólares américanos',
          symbol: '$',
          code: 'USD'
        }
      ],
      types: [
        'Compra de mercadería',
        'Pago de servicios',
        'Pago de combustible',
        'Marketing',
        'Gastos de representación',
        'Compra de activos'
      ],
      typesOfInvoices: [
        'Factura',
        'Nota de crédito'
      ],
      paymentMethods: [
        {
          id: 30,
          value: 'Crédito a 30 días'
        },
        {
          id: 15,
          value: 'Crédito a 15 días'
        },
        {
          id: 20,
          value: 'Crédito a 20 días'
        },
        {
          id: 45,
          value: 'Crédito a 45 días'
        },
        {
          id: 60,
          value: 'Crédito a 60 días'
        },
        {
          id: 90,
          value: 'Crédito a 90 días'
        },
        {
          id: 0,
          value: 'Contado'
        }
      ],
      years: [
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030
      ],
      months: [
        {
          id: 1,
          name: 'Enero'
        },
        {
          id: 2,
          name: 'Febrero'
        },
        {
          id: 3,
          name: 'Marzo'
        },
        {
          id: 4,
          name: 'Abril'
        },
        {
          id: 5,
          name: 'Mayo'
        },
        {
          id: 6,
          name: 'Junio'
        },
        {
          id: 7,
          name: 'Julio'
        },
        {
          id: 8,
          name: 'Agosto'
        },
        {
          id: 9,
          name: 'Septiembre'
        },
        {
          id: 10,
          name: 'Octubre'
        },
        {
          id: 11,
          name: 'Noviembre'
        },
        {
          id: 12,
          name: 'Diciembre'
        }
      ],
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      es: es
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        this.document = {
          base: 0,
          unaffected: 0,
          igv: 0,
          total: 0,
          currency: {
            symbol: ''
          },
          type: 'Compra de mercadería',
          typeOfInvoice: 'Factura',
          typeInvoice: null,
          paymentMethod: {
            id: 30,
            value: 'Crédito a 30 días'
          },
          year: this.years.find((y) => y === new Date().getFullYear()),
          month: this.months.find((m) => (m.id - 1) === new Date().getMonth())
        }
        this.$validator.reset()
      }
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async submitData () {
      try {
        const result = await this.$validator.validateAll()
        if (result) {
          this.progress = true
          const formatForSerie = '0000'
          const formatForDoc = '0000000000'
          // Get exchange rate
          const year = this.document.dateOfIssue.getFullYear()
          let month = this.document.dateOfIssue.getMonth() + 1
          let date = this.document.dateOfIssue.getDate()
          if (month < 10) {
            month = '0' + month
          }
          if (date < 10) {
            date = '0' + date
          }
          const responseExchange = await axios.post('https://southamerica-east1-anglidata-1aa70.cloudfunctions.net/exchangeRate-getExchangeRateForDate', {
            year,
            month,
            date
          }, {
            headers: {
              'Authorization': 'Bearer 78b1deb4-1b27-4943-9d83-818285cc59d6'
            }
          })
          // End
          let obj = {
            ...this.document,
            state: true,
            serie: formatForSerie.substr(this.document.serie.length) + this.document.serie,
            sequence: formatForDoc.substr(this.document.sequence.length) + this.document.sequence,
            paidOut: 0,
            status: 'hold',
            exchangeRate: responseExchange.data.venta,
            hash: parseInt(this.document.year + '' + this.document.month.id)
          }
          if (obj.typeOfInvoice !== 'Factura') {
            obj.total = obj.total * -1
          }
          obj.code = obj.serie + '-' + obj.sequence

          // Validate code
          const querySnap = await db.collection('invoices')
              .where('code', '==', obj.code)
              .where('provider.id', '==', obj.provider.id)
              .get()
          if (querySnap.docs.length === 0) {
            const doc = await db.collection('invoices').add({
              ...obj,
              createdAt: FieldValue.serverTimestamp(),
              uid: auth.currentUser.uid,
              user: {
                uid: auth.currentUser.uid,
                displayName: auth.currentUser.displayName
              },
              code: obj.serie + '-' + obj.sequence
            })
            obj.id = doc.id
            obj.createdAt = new Date()
            this.$emit('add', obj)
            this.$emit('closeSidebar')
            this.$vs.notify({
              color: 'success',
              title: 'Comprobante de compra',
              text: 'Comprobante ingresado correctamente.'
            })
          }
          else {
            this.$vs.notify({
              color: 'warning',
              title: 'Comprobante compra',
              text: 'Comprobante duplicado'
            })
          }
          // End
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error'
        })
      } finally {
        this.progress = false
      }
    },
    /**
     * Extract
     */
    extractDataToQR () {
      const data = this.document.gloss.split(']')
      this.document.igv = parseFloat(data[4].trim())
      this.document.total = parseFloat(data[5].trim())
      this.document.base = this.document.total - this.document.igv
      this.document.serie = data[2].trim()
      this.document.sequence = data[3].trim()
      // Review date
      this.document.dateOfIssue = new Date(data[6].trim())
      if (!(this.document.dateOfIssue instanceof Date)) {
        let splitDate = data[6].split('-')
        if (splitDate.length === 0) {
          splitDate = data[6].split('\'')
        }
        this.document.dateOfIssue = new Date(splitDate[1], splitDate[2], splitDate[0])
        if (!(this.document.dateOfIssue instanceof Date)) {
          this.document.dateOfIssue = null
        }
      }
      // End
      if (data[1] === '01') {
        this.document.currency = {
          id: 1,
          value: 'Soles',
          symbol: 'S/',
          code: 'PEN'
        }
      }
      else {
        this.document.currency = {
          id: 2,
          value: 'Dólares américanos',
          symbol: '$',
          code: 'USD'
        }
      }
      this.calculateEndDate()
      this.document.gloss = ''
    },
    /**
     * Calculate end date
     */
    calculateEndDate () {
      if (this.document.paymentMethod && this.document.dateOfIssue) {
        this.document.endDate = new Date(this.document.dateOfIssue)
        this.document.endDate = new Date(this.document.endDate.setDate(this.document.endDate.getDate() + this.document.paymentMethod.id))
        this.$validator.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52004;
  }

  ::v-deep .vs-sidebar {
    z-index: 52004;
    width: 1000px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
