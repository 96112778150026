<template>
  <div id="data-list-list-view" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancel="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-2">Periodo</h6>
        <!--Dates-->
        <div class="vx-row mt-4">
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Año*</label>
            <v-select placeholder="Selecciona" :options="years" :clearable="false"
                      label="value"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="year" class="mb-4 md:mb-0" name="year" id="year"
                      v-validate="'required'"/>
          </div>
          <div class="vx-col md:w-1/2">
            <label class="text-sm opacity-75">Mes*</label>
            <v-select placeholder="Selecciona" :options="months" :clearable="false"
                      label="name"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="month" class="mb-4 md:mb-0" name="month" id="month"
                      v-validate="'required'"/>
          </div>
        </div>
        <!--Providers-->
        <div>
          <div class="vx-row">
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona proveedor</h6>
              <v-select :options="providers" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="provider" class="mb-4 md:mb-0" name="client" id="client"/>
            </div>
            <div class="vx-col md:w-1/2">
              <h6 class="mt-5 mb-2">Selecciona tipo de comprobante</h6>
              <v-select :options="typesOfInvoices" :clearable="true"
                        :dir="$vs.rtl ? 'rtl' : 'ltr'"
                        v-model="typeOfInvoice" class="mb-4 md:mb-0" name="client" id="typeOfInvoice"/>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <new
        :providers="providers"
        @add="addDataSideBar"
        :isSidebarActive="showNewSidebar" @closeSidebar="showNewSidebar=false"/>

    <update
        :data="sidebarData"
        :providers="providers"
        @update="updateDataSideBar"
        :isSidebarActive="showUpdateSidebar" @closeSidebar="showUpdateSidebar=false"/>

    <payments
        @update="updateDataSideBar"
        :data="sidebarData"
        :isSidebarActive="showPaymentsSidebar"
        @closeSidebar="showPaymentsSidebar=false"/>

    <detail
        :data="sidebarData"
        :isSidebarActive="showDetailSidebar"
        @closeSidebar="showDetailSidebar=false"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="list">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">
            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                                <span>Exportar (Formato para Excel)</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="selected && $acl.check('admin')" @click="recordPayment">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Registrar pago (P)</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <div
              v-if="$acl.check('admin')"
              class="btn-add-new p-3 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
              @click="showNewSidebar= true">
            <feather-icon icon="PlusIcon" svgClasses="h-4 w-4"/>
            <span class="ml-2 text-base text-primary">Agregar comprobante</span>
          </div>
        </div>

        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{ list.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : list.length }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th sort-key="provider">Proveedor</vs-th>
        <vs-th sort-key="sequence">Nro. Doc.</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="paidOut">Por pagar</vs-th>
        <vs-th sort-key="status">Estado</vs-th>
        <vs-th sort-key="registrationDate">Fec. Emisión</vs-th>
        <vs-th sort-key="actions">Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="font-medium truncate">{{ tr.typeOfInvoice }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.provider.businessName }}</p>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.code }}</p>
          </vs-td>

          <vs-td>
            <p v-if="tr.currency.code">
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total)
              }}
            </p>
          </vs-td>

          <vs-td>
            <vs-chip v-if="tr.currency.code" color="danger" class="product-order-status">
              {{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: tr.currency.code,
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(tr.total - tr.paidOut)
              }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <vs-chip :color="getStatusColor(tr.status)" class="product-order-status">
              {{ getStateText(tr.status) | title }}
            </vs-chip>
          </vs-td>

          <vs-td>
            <p class="font-medium truncate">{{ tr.dateOfIssue | date(true) }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon class="mr-2" title="Ver" icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData = tr,showDetailSidebar = true"/>


            <feather-icon @click="openCancelDialog(tr)" v-if="tr.status === 'hold'" title="Anular"
                          icon="XIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current mr-3"/>


            <feather-icon v-if="tr.status === 'hold' && $acl.check('admin')" title="Editar" icon="EditIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData = tr,showUpdateSidebar = true"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>

  </div>
</template>

<script>
import New from './New'
import Update from './Update'
import Detail from './Detail'
import Payments from './Payments'

import { db } from '@/firebase/firebaseConfig'

const _ = require('lodash')

import { es } from 'vuejs-datepicker/dist/locale'

export default {
  components: {
    New,
    Detail,
    Payments,
    Update
  },
  data () {
    return {
      selected: {},
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      initProgress: false,
      providers: [],

      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      activePrompt: false,

      // Data Sidebar
      showNewSidebar: false,
      showDetailSidebar: false,
      showPaymentsSidebar: false,
      showUpdateSidebar: false,
      sidebarData: {},
      evt: null,
      // Filter
      year: null,
      month: null,
      es: es,
      provider: null,
      typeOfInvoice: null,
      years: [
        2022,
        2023,
        2024,
        2025,
        2026,
        2027,
        2028,
        2029,
        2030
      ],
      months: [
        {
          id: 1,
          name: 'Enero'
        },
        {
          id: 2,
          name: 'Febrero'
        },
        {
          id: 3,
          name: 'Marzo'
        },
        {
          id: 4,
          name: 'Abril'
        },
        {
          id: 5,
          name: 'Mayo'
        },
        {
          id: 6,
          name: 'Junio'
        },
        {
          id: 7,
          name: 'Julio'
        },
        {
          id: 8,
          name: 'Agosto'
        },
        {
          id: 9,
          name: 'Septiembre'
        },
        {
          id: 10,
          name: 'Octubre'
        },
        {
          id: 11,
          name: 'Noviembre'
        },
        {
          id: 12,
          name: 'Diciembre'
        }
      ],
      typesOfInvoices: [
        'Todos',
        'Factura',
        'Nota de crédito'
      ],
      selectedInvoice: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    }
    ,
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadInvoices()
      await this.loadProviders()
      // Set filter las 30 days
      this.startDate = new Date(new Date().setDate(new Date().getDate() - 30))
      this.endDate = new Date()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
    // Listen keyboard
    // this.evt = window.document
    // this.evt.addEventListener('keyup', this.listenKeyboard)
  },
  destroyed () {
    this.evt.removeEventListener('keyup', this.listenKeyboard)
  },
  methods: {
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    }
    ,
    /**
     * Update data of sidebar
     * */
    updateDataSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    }
    ,
    /**
     * Get status color
     * @param status
     * @returns {string}
     */
    getStatusColor (status) {
      if (status === 'hold') return 'warning'
      if (status === 'send') return 'success'
      if (status === 'rejected') return 'danger'
      return 'success'
    }
    ,
    /**
     * Get state text
     * @param value
     * @returns {string}
     */
    getStateText (value) {
      if (value === 'hold') return 'Generada'
      if (value === 'send') return 'Enviada'
      if (value === 'rejected') return 'Anulada'
    }
    ,
    /**
     * Export to excel
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(excel => {
        const list = _.cloneDeep(this.list)
        const newList = []
        list.forEach((o) => {
          if (o.status === 'hold') {
            o.date = o.dateOfIssue.getFullYear() + '-' + (o.dateOfIssue.getMonth() + 1) + '-' + o.dateOfIssue.getDate()
            o.provider = o.provider.businessName
            o.dollar = o.currency.code === 'USD' ? o.total : 0
            o.pen = o.currency.code === 'PEN' ? o.total : (o.total * o.exchangeRate)
            newList.push(o)
          }
        })
        const headerVal = ['date', 'code', 'provider', 'exchangeRate', 'dollar', 'pen']
        const headerTitle = ['Fecha de Emisión', 'Nro. Comprobante', 'Proveedor', 'Tipo de cambio', 'Dólares', 'Soles']
        const data = this.formatJson(headerVal, newList)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Registro de compras',
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    }
    ,
    /**
     * Get providers
     * @returns {Promise<void>}
     */
    async loadProviders () {
      const querySnapshot = await db.collection('providers')
          .where('state', '==', true)
          .where('typeDocument', '==', 'RUC')
          .orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          label: doc.data().document + ' - ' + doc.data().businessName
        }
        this.providers.push(obj)
      })
    }
    ,
    /**
     * Get products
     * @returns {Promise<void>}
     */
    async loadProducts () {
      this.products = []
      const querySnapshot = await db.collection('products').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let product = {
          id: doc.id,
          brand: doc.data().brand,
          category: doc.data().category,
          name: doc.data().name,
          sku: doc.data().sku,
          label: doc.data().name + ' - ' + doc.data().sku
        }
        this.products.push(product)
      })
    }
    ,
    /**
     * Get invoices
     * */
    async loadInvoices () {
      const querySnapshot = await db.collection('invoices').orderBy('createdAt', 'desc').limit(400).get()
      querySnapshot.forEach((doc) => {
        let obj = {
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toDate(),
          dateOfIssue: doc.data().dateOfIssue.toDate(),
          endDate: doc.data().endDate.toDate(),
          typeOfInvoice: doc.data().typeOfInvoice || 'Factura'
        }
        this.list.push(obj)
      })
    }
    ,
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.year && this.month) {
          this.initProgress = true
          this.list = []
          let querySnapshot = {}

          if (this.provider) {
            if (this.typeOfInvoice === 'Todos') {
              querySnapshot = await db.collection('invoices')
                  .where('hash', '==', parseInt(this.year + '' + this.month.id))
                  .where('provider.id', '==', this.provider.id)
                  .orderBy('createdAt', 'asc').get()
            } else {
              querySnapshot = await db.collection('invoices')
                  .where('hash', '==', parseInt(this.year + '' + this.month.id))
                  .where('provider.id', '==', this.provider.id)
                  .where('typeOfInvoice', '==', this.typeOfInvoice)
                  .orderBy('createdAt', 'asc').get()
            }

          } else {
            if (this.typeOfInvoice === 'Todos') {
              querySnapshot = await db.collection('invoices')
                  .where('hash', '==', parseInt(this.year + '' + this.month.id))
                  .orderBy('createdAt', 'asc').get()
            }else {
              querySnapshot = await db.collection('invoices')
                  .where('hash', '==', parseInt(this.year + '' + this.month.id))
                  .where('typeOfInvoice', '==', this.typeOfInvoice)
                  .orderBy('createdAt', 'asc').get()
            }
          }

          querySnapshot.forEach((doc) => {
            let obj = {
              id: doc.id,
              ...doc.data(),
              createdAt: doc.data().createdAt.toDate(),
              dateOfIssue: doc.data().dateOfIssue.toDate(),
              endDate: doc.data().endDate.toDate()
            }
            this.list.push(obj)
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    }
    ,
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      await this.loadInvoices()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Record payment
     */
    recordPayment () {
      if (this.selected.id) {
        this.showPaymentsSidebar = true
        this.sidebarData = this.selected
      } else {
        this.$vs.notify({
          color: 'warning',
          title: 'Comprobante de compra',
          text: 'El comprobante debe haber sido enviado.'
        })
      }
    },
    /**
     * Listen keyboard
     */
    listenKeyboard (ev) {
      if (this.selected.id && !this.showDetailSidebar && !this.showPaymentsSidebar && !this.activePrompt) {
        if (ev.keyCode === 79) { // Open (O)
          this.showDetailSidebar = true
          this.sidebarData = this.selected
        } else if (ev.keyCode === 80 && this.$acl.check('admin')) { // Payment (P)
          this.recordPayment()
        }
      }
    },
    /**
     * Cancel
     * @param tr
     */
    openCancelDialog (tr) {
      this.selectedInvoice = tr
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmación',
        text: `¿Deseas anular el comprobante "${tr.code}"?`,
        accept: this.acceptDialogLowCommunication,
        cancel: '',
        parameters: [tr],
        acceptText: 'Anular',
        cancelText: 'Cancelar'
      })
    },
    /**
     * Accept cancel
     * @returns {Promise<void>}
     */
    async acceptDialogLowCommunication () {
      try {
        this.initProgress = true

        // Update status
        await db.collection('invoices').doc(this.selectedInvoice.id).update({
          status: 'rejected',
          paidOut: this.selectedInvoice.total
        })

        const objInvoice = this.list.find((o) => o.id === this.selectedInvoice.id)
        objInvoice.status = 'rejected'
        objInvoice.paidOut = objInvoice.total

        this.selectedInvoice = {}
        this.$vs.notify({
          color: 'success',
          title: 'Comprobante de compra',
          text: 'Comprobante anulado correctamente'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Error'
        })
      } finally {
        this.initProgress = false
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }

  .product-name {
    max-width: 23rem;
  }
}
</style>
